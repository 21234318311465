import React from 'react'
import {Link} from 'gatsby'

const CTABanner = () => {
    return (
        <div className="cta-banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <h2>Don't know where to start?<br />Talk to us today!</h2>
                        <p>We're here to answer your questions and get you on your <br /> way to the best Home Loan experience.</p>
                    </div>
                    <div className="col-lg-4 col-xl-3 ml-lg-auto mt-4 mt-lg-0 d-lg-flex flex-column align-items-stretch justify-content-center">
                        {/* <Link to="/book-a-call" className="default-btn primary-btn btn-lg mr-3 mr-lg-0 mb-lg-3">
                            Talk to an Expert
                        </Link> */}
                        <Link to="/applynow" className="default-btn btn-lg primary-btn btn-lg">
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CTABanner;